import React from 'react';

import { HideAppBar } from './menuBar';

const NavBar = () => {
    return(
        <HideAppBar />
    );
}

export { NavBar };