import React from 'react';

// local imports
import Banner from "../components/Banner";

const Home = () => {
  return(
    <>
      <div className="main">
        <section>
          <Banner></Banner>
        </section>
      </div>
    </>
  )
}

export { Home };